// custom typefaces
import "@fontsource-variable/montserrat"
import "@fontsource/merriweather"
// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"

import React from "react"
import RootElement from "./src/components/RootElement"

// gatsby-browser.js
if (typeof global === 'undefined') {
  window.global = window;
}
export const wrapRootElement = ({ element }) => {
  return <RootElement>{element}</RootElement>
}



