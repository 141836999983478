import React from "react"
import { AuthProvider } from "../context/AutProvider"
import { SnackbarProvider } from "notistack"

const RootElement = ({ children }) => {
  return (
    <AuthProvider>
      <SnackbarProvider
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      >
        {children}
      </SnackbarProvider>
    </AuthProvider>
  )
}

export default RootElement
