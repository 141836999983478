import React, { createContext, useState } from "react"
import { auth, database } from "../components/config"
import {
  signInWithPopup,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
} from "firebase/auth"

import { getDatabase, ref, child, get } from "firebase/database"

import { useLogout } from "../hooks/useLogout"

export const AppContext = createContext({
  isLogin: false,
  user: null,
  handleLogin: () => {},
  signin: () => {},
  signout: () => {},
})

const provider = new GoogleAuthProvider()

export const AuthProvider = ({ children }) => {
  const [isLogin, setIsLogin] = useState(false)
  const [user, setUser] = React.useState(null)
  const { logout } = useLogout()

  const handleLogin = () => {
    setIsLogin(!isLogin)
  }

  function signin(email, password, handleSuccess, handleFailure) {
    console.log(email, password)
    signInWithEmailAndPassword(auth, email, password)
      .then(userCredential => {
        // Signed in
        let user = userCredential.user
        const userId = user.uid

        const dbRef = ref(database)
        console.log(`users/${userId}`)
        get(child(dbRef, `users/${userId}`))
          .then(snapshot => {
            if (snapshot.exists()) {
              user = { ...user, ...snapshot.val() }
              setUser(user)
              handleLogin()
              handleSuccess(user)
            } else {
              console.log("No data available")
            }
          })
          .catch(error => {
            console.log("error 2 -----------", error.message)

            console.error(error)
          })

        // ...
      })
      .catch(error => {
        const errorCode = error.code
        const errorMessage = error.message
        console.log("" + errorCode + " " + errorMessage)
        handleFailure(error)
      })
  }

  function signout() {
    logout()
    setIsLogin(false)
    setUser(null)
  }

  function signin2() {
    signInWithPopup(auth, provider)
      .then(result => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result)
        const token = credential.accessToken
        // The signed-in user info.
        const user = result.user
        setUser(user)
        handleLogin()
        console.log(user)
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch(error => {
        // Handle Errors here.
        const errorCode = error.code
        const errorMessage = error.message
        // The email of the user's account used.
        const email = error.customData.email
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error)
        // ...
      })
  }

  return (
    <AppContext.Provider
      value={{
        isLogin,
        user,
        handleLogin,
        signin,
        signout,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
