import { initializeApp } from "firebase/app"
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
} from "firebase/auth"
import { getDatabase } from "firebase/database"
const firebaseConfig = {
  apiKey: "AIzaSyAoy6n-hRrBEddpYpbNCjTmwB7-Sb3Fio8",
  authDomain: "sound-chat-a418a.firebaseapp.com",
  databaseURL: "https://sound-chat-a418a-default-rtdb.firebaseio.com",
  projectId: "sound-chat-a418a",
  storageBucket: "sound-chat-a418a.appspot.com",
  messagingSenderId: "790088246551",
  appId: "1:790088246551:web:dea5a967a20f333df08352",
}

const firebaseApp = initializeApp(firebaseConfig)
const auth = getAuth(firebaseApp)
const database = getDatabase(firebaseApp)

export { auth, database }
