import {auth} from '../components/config'
import {signOut} from "firebase/auth"
export const useLogout = () => {

    const logout = () => {
        console.log('in')
        signOut(auth)
        .then(()=>{
            console.log('user signed out')
        })
        .catch((err) =>{
            console.log(err.message)
        })
    }
return {logout}
}